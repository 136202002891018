import React, { memo, forwardRef } from 'react';
import styles from './BoxedSelectionControl.scss';

export type BoxedSelectionControlProps = {
  checked?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  error?: boolean;
  name: string;
  onChange?: (v: string, c: boolean) => void;
  type: 'checkbox' | 'radio';
  value: string;
};

const BoxedSelectionControl = forwardRef<HTMLInputElement, BoxedSelectionControlProps>(
  (
    {
      checked,
      children,
      disabled = false,
      error = false,
      name,
      onChange,
      type,
      value,
    }: BoxedSelectionControlProps,
    ref,
  ) => {
    const sanitizedValue = value.replace(' ', '-');
    const id = name === value ? sanitizedValue : `${name.replace(' ', '-')}_${sanitizedValue}`;

    return (
      <div className={styles.field}>
        <input
          aria-invalid={!!error}
          checked={checked}
          className={styles.input}
          disabled={disabled}
          id={id}
          name={name}
          onChange={() => onChange && onChange(value, !checked)}
          type={type}
          value={value}
          ref={ref}
        />
        <label aria-disabled={disabled} aria-selected={checked} htmlFor={id}>
          {children}
        </label>
      </div>
    );
  },
);

export default memo(BoxedSelectionControl);
